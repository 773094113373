import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Common } from 'app/model/entity/common';
import { UserLocation } from 'app/model/entity/fuji-climber-management/user-location';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { Subscription } from 'rxjs';
import { FujiClimberManagementService } from './../../service/fuji-climber-management.service';

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @ViewChild('mapContainer', { static: true }) mapContainer!: ElementRef;
  @Output() climberSelected: EventEmitter<string> = new EventEmitter();
  _userLocations: any[] = [];
  _userLocation: UserLocation;
  infoWindow: google.maps.InfoWindow | null = null;
  clickTimeout: any = null;
  lastClickTime: number = 0;
  Helper = Helper;
  readonly DOUBLE_CLICK_THRESHOLD = 500; // milliseconds

  @Input() isScriptLoaded: string;
  @Input()
  set userLocations(value: any[]) {
    this._userLocations = value;
    if (this.map) {
      this.updateVisibleMarkers();
    }
  }
  private observer: MutationObserver;
  /**
   * get userLocations
   */
  get userLocations() {
    return this._userLocations;
  }

  @Input()
  set userLocation(value: UserLocation) {
    this._userLocation = value;
    if (this.map) {
      this.updateVisibleMarkers();
      if (this.showMovementHistory && this._userLocation?.isMarker) {
        this.loadMovementHistory();
        if (this.showTimeStamps && this.map.getZoom() >= 15) {
          this.onTimeStampsChange();
        }
      } else {
        this.clearMovementHistory();
      }
    }
  }

  /**
   * get userLocation
   */
  get userLocation() {
    return this._userLocation;
  }
  map: google.maps.Map;
  markers: Map<string, google.maps.Marker> = new Map();
  userLocationMarker: google.maps.Marker | null = null;
  gsiMapType: google.maps.ImageMapType;
  readonly markerSvgIcons = {
    userLocations: `data:image/svg+xml,${encodeURIComponent(`<?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 25.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
     y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#50DFD5;stroke:#000000;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  </style>
  <path class="st0" d="M15.03,0.57c-4.94,0-8.94,4-8.94,8.94c0,1.46,0.35,2.83,0.97,4.05l7.98,15.88L23,13.56
    c0.62-1.22,0.97-2.59,0.97-4.05C23.97,4.57,19.97,0.57,15.03,0.57z M15.03,12.82c-1.83,0-3.31-1.48-3.31-3.31
    c0-1.83,1.48-3.31,3.31-3.31s3.31,1.48,3.31,3.31C18.34,11.34,16.85,12.82,15.03,12.82z"/>
  </svg>`)}`,

    userLocation: `data:image/svg+xml,${encodeURIComponent(`<?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 25.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
     y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#F8B62D;stroke:#000000;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  </style>
  <path class="st0" d="M15,0.57c-4.94,0-8.94,4-8.94,8.94c0,1.46,0.35,2.83,0.97,4.05L15,29.44l7.98-15.88
    c0.62-1.22,0.97-2.59,0.97-4.05C23.94,4.57,19.94,0.57,15,0.57z M15,12.82c-1.83,0-3.31-1.48-3.31-3.31c0-1.83,1.48-3.31,3.31-3.31
    c1.83,0,3.31,1.48,3.31,3.31C18.31,11.34,16.83,12.82,15,12.82z"/>
  </svg>`)}`,

    lodge: `data:image/svg+xml,${encodeURIComponent(`<?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 25.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve">
    <style type="text/css">
     .st0{fill:#595959;stroke:#595959;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
    </style>
    <g class="st0">
     <polygon points="1.68,8.75 11,0.45 20.32,8.75"/>
     <rect x="1.68" y="8.75" width="18.63" height="12.8"/>
     <rect x="15.4" y="0.79" width="3.48" height="8.99"/>
    </g>
    </svg>`)}`
  };

  readonly TRAIL_STYLES = {
    'fujinomiya_trail.geojson': { color: '#168fbf', weight: 3, opacity: 0.8 },
    'gotenba_trail.geojson': { color: '#568b41', weight: 3, opacity: 0.8 },
    'subashiri_trail.geojson': { color: '#c94c48', weight: 3, opacity: 0.8 },
    'yoshida_trail.geojson': { color: '#b2a200', weight: 3, opacity: 0.8 },
    'walking_path.geojson': { color: '#8f6552', weight: 3, opacity: 0.8 }
  };
  safeAttribution: SafeHtml;
  public settings: MapSettings = {
    baseMapUrl: '',
    attribution: '',
    centerCoordinates: [0, 0],
    zoomLevel: {
      default: 0,
      max: 0,
      min: 0
    }
  };

  isZoomInDisabled = false;
  isZoomOutDisabled = false;

  showMovementHistory: boolean = false;
  showTimeStamps: boolean = false;
  movementHistoryPath: google.maps.Polyline | null = null;
  timeStampMarkers: ITimeMarkerOverlay[] = [];
  arrowMarkers: google.maps.Marker[] = [];
  mountainTrails: Map<string, google.maps.Polyline[]> = new Map();
  mountainLodges: google.maps.Marker[] = [];
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  private googleMapsApiKey: string = '';
  routes: any;
  lodges: any;
  languageKey: string;
  private commonObject: Common; // Common object

  constructor(
    private sanitizer: DomSanitizer,
    private fujiClimberManagementService: FujiClimberManagementService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    const styledAttribution = this.settings.attribution.replace(/(<[^>]+)>/g, '$1 style="color: #4e93a5 !important;">');
    this.safeAttribution = this.sanitizer.bypassSecurityTrustHtml(styledAttribution);
    this.subscriptions.push(
      // Get language
      this.translateService.onLangChange.subscribe(async () => {
        this.languageKey = this.commonService.getCommonObject().setting?.language == 'en' ? 'en' : 'ja';

        this.markers.forEach(marker => {
          marker.setMap(null);
        });
        this.markers.clear();
        this.clearMovementHistory();
        if (this.infoWindow) {
          this.infoWindow.close();
          this.infoWindow = null;
        }
        this.resetView();
        await this.updateVisibleMarkers();
        if (this.showMovementHistory) {
          this.loadMovementHistory();
          if (this.showTimeStamps && this.map.getZoom() >= 15) {
            this.onTimeStampsChange();
          }
        }
      })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language == 'en' ? 'en' : 'ja';
    try {
      await this.loadGoogleMapsApi();

      // Load all data in parallel using Promise.all
      const [mapSettings, routes, lodges] = await Promise.all([
        this.fujiClimberManagementService.getMapSetting().toPromise(),
        this.fujiClimberManagementService.getRoute().toPromise(),
        this.fujiClimberManagementService.getMountainLodge().toPromise()
      ]);

      // Apply map settings
      this.settings = mapSettings;
      this.routes = routes;
      this.lodges = lodges;
      this.safeAttribution = this.sanitizer.bypassSecurityTrustHtml(this.settings.attribution);

      // Initialize map after settings are loaded
      this.initMap();

      // Initialize trails and lodges with the loaded data
      await this.initializeTrailsAndLodges(this.routes, this.lodges);
      this.setupDOMObserver();
    } catch (error) {
      console.error('Error loading Google Maps API or data', error);
      Helper.handleError(error, this.translateService, this.dialogService);
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private async loadGoogleMapsApi(): Promise<void> {
    try {
      // Gọi API để lấy key từ backend
      this.googleMapsApiKey = await this.fujiClimberManagementService.getGoogleApiKey().toPromise();

      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsApiKey}&libraries=geometry&loading=async`;
        script.async = true;
        script.defer = true;

        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          reject(new Error('Không thể tải Google Maps API'));
        };

        document.head.appendChild(script);
      });
    } catch (error) {
      console.error('Lỗi khi tải Google Maps API:', error);
      throw error;
    }
  }

  /**
   * initMap
   */
  initMap(): void {
    if ((window as any).google && (window as any).google.maps) {
      // Khởi tạo GSI Map Type
      this.gsiMapType = new (window as any).google.maps.ImageMapType({
        getTileUrl: (coord: any, zoom: number) => {
          return this.settings.baseMapUrl
            .replace('{z}', zoom.toString())
            .replace('{x}', coord.x.toString())
            .replace('{y}', coord.y.toString());
        },
        tileSize: new (window as any).google.maps.Size(256, 256),
        minZoom: this.settings.zoomLevel.min,
        maxZoom: this.settings.zoomLevel.max
      });

      // Cấu hình map options
      const mapOptions = {
        center: {
          lat: this.settings.centerCoordinates[1],
          lng: this.settings.centerCoordinates[0]
        },
        zoom: this.settings.zoomLevel.default,
        minZoom: this.settings.zoomLevel.min,
        maxZoom: this.settings.zoomLevel.max,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        scaleControl: true,
        scaleControlOptions: {
          style: google.maps.ScaleControlStyle.DEFAULT
        },
        keyboardShortcuts: false,
        disableDefaultUI: true,
        styles: [
          {
            featureType: 'all',
            elementType: 'all',
            stylers: [{ visibility: 'off' }]
          }
        ]
      };

      // Cấu hình container style
      const customMapDiv = this.mapContainer.nativeElement;
      customMapDiv.style.position = 'relative';
      customMapDiv.style.overflow = 'hidden';

      // Khởi tạo map
      try {
        this.map = new (window as any).google.maps.Map(this.mapContainer.nativeElement, mapOptions);

        // Set map type
        this.map.mapTypes.set('GSI', this.gsiMapType);
        this.map.setMapTypeId('GSI');

        // Đợi map load xong và xử lý UI elements
        google.maps.event.addListenerOnce(this.map, 'idle', () => {
          // Ẩn các UI elements không cần thiết
          const elements = document.querySelectorAll('.gm-style-cc, .gmnoprint, a[href^="https://maps.google.com"]');
          elements.forEach((element: any) => {
            if (element) {
              element.style.display = 'none';
            }
          });

          // Chỉ cập nhật markers sau khi map đã hoàn toàn sẵn sàng
          if (this.userLocations && this.userLocations.length > 0) {
            this.updateVisibleMarkers();
          }
        });

        // Xử lý sự kiện zoom changed
        this.map.addListener('zoom_changed', () => {
          const currentZoom = this.map.getZoom() || 0;
          this.isZoomInDisabled = currentZoom >= this.settings.zoomLevel.max;
          this.isZoomOutDisabled = currentZoom <= this.settings.zoomLevel.min;
        });
      } catch (error) {
        console.error('Lỗi khởi tạo bản đồ:', error);
      }
    } else {
      console.error('Google Maps API chưa được tải');
    }
  }

  /**
   * zoomIn
   */
  zoomIn(): void {
    if (this.map) {
      const currentZoom = this.map.getZoom() || 0;
      if (currentZoom < this.settings.zoomLevel.max) {
        this.map.setZoom(currentZoom + 1);
      }
    }
  }

  /**
   * zoomOut
   */
  zoomOut(): void {
    if (this.map) {
      const currentZoom = this.map.getZoom() || 0;
      if (currentZoom > this.settings.zoomLevel.min) {
        this.map.setZoom(currentZoom - 1);
      }
    }
  }

  /**
   * resetView
   */
  resetView(): void {
    if (this.map) {
      this.map.setCenter({
        lat: this.settings.centerCoordinates[1],
        lng: this.settings.centerCoordinates[0]
      });
      this.map.setZoom(this.settings.zoomLevel.default);
    }
  }

  /**
   * updateVisibleMarkers
   */
  async updateVisibleMarkers(): Promise<void> {
    if (!this.map) return;
    if (this.infoWindow && (this.infoWindow as any)?.source !== 'lodgeMarker') {
      this.infoWindow.close();
      this.infoWindow = null;
    }
    if (!this.userLocations || !Array.isArray(this.userLocations) || this.userLocations.length === 0) {
      this.resetView();
      // Xóa tất cả marker màu xanh nếu userLocations rỗng/không hợp lệ
      this.markers.forEach(marker => {
        marker.setMap(null);
      });
      this.markers.clear();
      this.clearMovementHistory();
    }

    const currentMarkerIds = new Set(
      this.userLocations
        .filter(location => location.isMarker && location.latitude && location.longitude)
        .map(location => location.orderDistributionId)
    );

    // Xóa các marker không còn tồn tại trong userLocations
    this.markers.forEach((marker, markerId) => {
      if (!currentMarkerIds.has(markerId)) {
        marker.setMap(null);
        this.markers.delete(markerId);
      }
    });

    // 1. Xử lý các marker xanh (userLocations)
    if (this.userLocations && Array.isArray(this.userLocations)) {
      this.userLocations.forEach(location => {
        if (!location.latitude || !location.longitude) return;

        const markerId = location.orderDistributionId;
        const existingMarker = this.markers.get(markerId);
        const currentPosition = { lat: Number(location.latitude), lng: Number(location.longitude) };

        // Chỉ hiển thị marker xanh nếu:
        // - Có isMarker = true
        // - KHÔNG phải là điểm đang được chọn trong userLocation
        const shouldShowBlueMarker =
          location.isMarker && (!this.userLocation || location.orderDistributionId !== this.userLocation.orderDistributionId);

        if (shouldShowBlueMarker) {
          if (!existingMarker) {
            // Tạo marker mới nếu chưa tồn tại
            const marker = new google.maps.Marker({
              position: currentPosition,
              map: this.map,
              icon: {
                url: this.markerSvgIcons.userLocations,
                scaledSize: new google.maps.Size(30, 30),
                anchor: new google.maps.Point(15, 30)
              },
              optimized: false,
              zIndex: 1
            });
            marker.set('orderDistributionId', location.orderDistributionId);
            marker.addListener('click', () => this.handleMarkerClick(marker, location));
            this.markers.set(markerId, marker);
          } else {
            // So sánh vị trí cũ và mới
            const existingPosition = existingMarker.getPosition();
            if (existingPosition.lat() !== currentPosition.lat || existingPosition.lng() !== currentPosition.lng) {
              // Chỉ cập nhật vị trí nếu có thay đổi
              existingMarker.setPosition(currentPosition);
            }
            // Đảm bảo marker được hiển thị
            existingMarker.setMap(this.map);
          }
        } else {
          // Ẩn marker nếu không thỏa điều kiện hiển thị
          if (existingMarker) {
            existingMarker.setMap(null);
          }
        }
      });
    }

    // 2. Xử lý marker vàng (userLocation)
    if (this.userLocation?.locationRecords?.length > 0 && this.userLocation.isMarker) {
      // Lấy record có locationRecordedAt mới nhất
      const lastRecord = this.userLocation.locationRecords.sort(
        (a, b) => new Date(b.locationRecordedAt).getTime() - new Date(a.locationRecordedAt).getTime()
      )[0];

      if (lastRecord?.latitude && lastRecord?.longitude) {
        const position = {
          lat: Number(lastRecord.latitude),
          lng: Number(lastRecord.longitude)
        };

        if (this.userLocationMarker) {
          // So sánh vị trí cũ và mới của marker vàng
          const existingPosition = this.userLocationMarker.getPosition();
          if (
            existingPosition.lat() !== position.lat ||
            existingPosition.lng() !== position.lng ||
            this.userLocationMarker.get('orderDistributionId') !== this.userLocation.orderDistributionId
          ) {
            // Cập nhật vị trí và orderDistributionId nếu có thay đổi
            this.userLocationMarker.setPosition(position);
            this.userLocationMarker.set('orderDistributionId', this.userLocation.orderDistributionId);
          }
          // Hiển thị marker
          this.userLocationMarker.setMap(this.map);
        } else {
          // Tạo marker vàng mới
          this.userLocationMarker = new google.maps.Marker({
            position: position,
            map: this.map,
            icon: {
              url: this.markerSvgIcons.userLocation,
              scaledSize: new google.maps.Size(30, 30),
              anchor: new google.maps.Point(15, 30)
            },
            optimized: false,
            zIndex: 1000
          });
          this.userLocationMarker.set('orderDistributionId', this.userLocation.orderDistributionId);
          this.userLocationMarker.addListener('click', () => this.handleMarkerClick(this.userLocationMarker!, this.userLocation));
        }
      }
    } else if (this.userLocationMarker) {
      // Ẩn marker vàng nếu không có dữ liệu hoặc isMarker = false
      this.userLocationMarker.setMap(null);
      this.userLocationMarker = null;
    }
  }

  /**
   * handleMarkerClick
   */
  handleMarkerClick(marker: google.maps.Marker, userData: any): void {
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - this.lastClickTime;

    if (timeDiff < this.DOUBLE_CLICK_THRESHOLD) {
      // Double click
      clearTimeout(this.clickTimeout);
      this.handleDoubleClick(marker, userData);
    } else {
      // Single click
      this.clickTimeout = setTimeout(() => {
        this.handleSingleClick(marker, userData);
      }, this.DOUBLE_CLICK_THRESHOLD);
    }

    this.lastClickTime = currentTime;
  }

  /**
   * handleDoubleClick
   */
  handleDoubleClick(marker: google.maps.Marker, userData: any): void {
    const clickedOrderDistributionId = marker.get('orderDistributionId');
    if (!clickedOrderDistributionId) return;

    const clickedLocation = this.userLocations.find(loc => loc.orderDistributionId === clickedOrderDistributionId);
    if (!clickedLocation) return;

    // Close existing info window
    if (this.infoWindow) {
      this.infoWindow.close();
    }

    this.climberSelected.emit(clickedOrderDistributionId);
  }

  /**
   * handleSingleClick
   * @param marker
   * @param userData
   */
  handleSingleClick(marker: google.maps.Marker, userData: any): void {
    if (this.infoWindow) {
      this.infoWindow.close();
    }

    const location =
      this.userLocations.find(loc => loc.orderDistributionId === marker.get('orderDistributionId')) ||
      (this.userLocation?.orderDistributionId === marker.get('orderDistributionId') ? this.userLocation : null);

    if (location) {
      const content = this.createInfoWindowContent({
        latitude: location.latitude || location.locationRecords?.[0]?.latitude,
        longitude: location.longitude || location.locationRecords?.[0]?.longitude,
        name: location.recipientName,
        phone: location.phoneNumber,
        email: location.email,
        lastLocationTime: location.lastLocationRecordedAt || location.locationRecords?.[0]?.locationRecordedAt
      });

      this.infoWindow = new google.maps.InfoWindow({
        content: content
      });
      (this.infoWindow as any).open(this.map, marker);
    }
  }

  /**
   * createInfoWindowContent
   */
  createInfoWindowContent(data: {
    latitude: number;
    longitude: number;
    name: string;
    phone: string;
    email: string;
    lastLocationTime: string;
  }): string {
    return `
      <div style="font-family: sans-serif; position: relative; min-width: 280px;">
        <div>${this.translateService.instant('fuji-climber-management.tab-climber-location-information.latitude-label')}${
      data.latitude
    }</div>
        <div class = "label-popup-maker">${this.translateService.instant(
          'fuji-climber-management.tab-climber-location-information.longitude-label'
        )}${data.longitude}</div>
        <div class = "label-popup-maker textover mw250" style="max-width: 350px" title="${this.translateService.instant(
          'fuji-climber-management.tab-climber-location-information.the-name-of-the-climber-label'
        )}${data.name}">${this.translateService.instant(
      'fuji-climber-management.tab-climber-location-information.the-name-of-the-climber-label'
    )}${data.name}</div>
        <div class = "label-popup-maker">${this.translateService.instant(
          'fuji-climber-management.tab-climber-location-information.telephone-number-label'
        )}${Helper.formatPhoneNumber(data.phone)}</div> 
        <div class = "label-popup-maker textover mw250" style="max-width: 350px" title="${this.translateService.instant(
          'fuji-climber-management.tab-climber-location-information.email-address-label'
        )}${data.email}">${this.translateService.instant('fuji-climber-management.tab-climber-location-information.email-address-label')}${
      data.email
    }</div>
        <div class = "label-popup-maker">${this.translateService.instant(
          'fuji-climber-management.tab-climber-location-information.last-access-date-label'
        )}${data.lastLocationTime}</div>
      </div>
    `;
  }

  /**
   * Xử lý khi toggle lịch sử di chuyển
   */
  async onMovementHistoryChange(): Promise<void> {
    if (!this.showMovementHistory) {
      this.showTimeStamps = false;
      this.clearMovementHistory();
    } else if (this.userLocation) {
      this.loadMovementHistory();
    }
  }

  /**
   * Xử lý khi toggle hiển thị thời gian
   */
  /**
   * Xử lý khi toggle hiển thị thời gian
   */
  onTimeStampsChange(): void {
    if (this.showTimeStamps && this.map.getZoom() >= 15) {
      if (this.userLocation?.locationRecords?.length && this.userLocation?.isMarker) {
        // Lấy 200 điểm mới nhất và sắp xếp theo thời gian
        const records = this.userLocation.locationRecords
          .slice(-200)
          .sort((a, b) => new Date(a.locationRecordedAt).getTime() - new Date(b.locationRecordedAt).getTime());
        this.showTimeMarkers(records);
      }
    } else {
      this.clearTimeMarkers();
    }
  }

  /**
   * Load và hiển thị lịch sử di chuyển
   */
  loadMovementHistory(): void {
    this.clearMovementHistory(); // Clear existing history first
    if (!this.userLocation?.locationRecords?.length || ! this.userLocation?.isMarker) return;

    const records = this.userLocation.locationRecords
      .slice(-200)
      .sort((a, b) => new Date(a.locationRecordedAt).getTime() - new Date(b.locationRecordedAt).getTime());

    // Tạo path coordinates và polyline như cũ
    const pathCoordinates = records.map(record => ({
      lat: Number(record.latitude),
      lng: Number(record.longitude)
    }));

    this.movementHistoryPath = new google.maps.Polyline({
      path: pathCoordinates,
      strokeColor: '#000000',
      strokeOpacity: 0.8,
      strokeWeight: this.map.getZoom() >= 15 ? 6 : 4,
      zIndex: 2,
      map: this.map
    });

    // Chỉ tạo arrows một lần và lưu trữ chúng
    this.createArrowMarkers(records);

    // Cập nhật visibility dựa trên zoom level
    const currentZoom = this.map.getZoom();
    this.updateArrowMarkersVisibility(currentZoom >= 15);

    if (this.showTimeStamps && currentZoom >= 15) {
      this.showTimeMarkers(records);
    }
  }

  updateArrowMarkersVisibility(isVisible: boolean): void {
    this.arrowMarkers.forEach(marker => {
      marker.setMap(isVisible ? this.map : null);
    });
  }

  /**
   * Load và hiển thị mountain trails và lodges
   */
  initializeTrailsAndLodges(routes: Map<string, string>, lodgesData: any): void {
    try {
      // Clear existing lodges if any
      this.mountainLodges.forEach(lodge => {
        lodge.setMap(null);
      });
      this.mountainLodges = [];

      // Process each trail type
      Object.entries(routes).forEach(([type, geoJsonString]) => {
        if (!geoJsonString) return;

        try {
          // Parse GeoJSON string to object
          const data = JSON.parse(geoJsonString);

          // Get style for this trail type - using full filename
          const style = this.TRAIL_STYLES[type];
          if (!style) {
            console.warn(`No style found for trail: ${type}`);
            return;
          }

          const lines: google.maps.Polyline[] = [];

          // Validate and process GeoJSON features
          if (data.features && Array.isArray(data.features)) {
            data.features.forEach(feature => {
              if (feature.geometry?.type === 'LineString' && Array.isArray(feature.geometry.coordinates)) {
                const path = feature.geometry.coordinates.map(coord => ({
                  lat: coord[1],
                  lng: coord[0]
                }));

                const line = new google.maps.Polyline({
                  path,
                  strokeColor: style.color,
                  strokeOpacity: style.opacity,
                  strokeWeight: this.map.getZoom() >= 15 ? 5 : 3,
                  map: this.map
                });

                lines.push(line);
              }
            });
          }

          // Store the lines using full filename as key
          this.mountainTrails.set(type, lines);
        } catch (parseError) {
          console.error(`Error parsing GeoJSON for ${type}:`, parseError);
        }
      });

      // Process mountain lodges data
      if (lodgesData) {
        if (lodgesData?.features && Array.isArray(lodgesData?.features)) {
          lodgesData?.features.forEach(feature => {
            if (feature.geometry?.type === 'Point' && Array.isArray(feature.geometry.coordinates)) {
              const position = {
                lat: feature.geometry.coordinates[1],
                lng: feature.geometry.coordinates[0]
              };

              // Create lodge marker with SVG icon
              const lodgeMarker = new google.maps.Marker({
                position: position,
                map: this.map.getZoom() >= 15 ? this.map : null,
                icon: {
                  url: this.markerSvgIcons.lodge,
                  scaledSize: new google.maps.Size(22, 22),
                  anchor: new google.maps.Point(11, 22)
                },
                optimized: false
              });

              // Add click listener for lodge info
              lodgeMarker.addListener('click', () => {
                if (this.infoWindow) {
                  this.infoWindow.close();
                }

                const content = `
                   <div style="font-family: sans-serif; position: relative; min-width: 180px;">
                               <div>${this.translateService.instant(
                                 'fuji-climber-management.tab-climber-location-information.latitude-label'
                               )}${feature?.geometry.coordinates[1]}</div>
            <div>${this.translateService.instant('fuji-climber-management.tab-climber-location-information.longitude-label')}${
                  feature?.geometry.coordinates[0]
                }</div>

                      <div>${this.translateService.instant('fuji-climber-management.tab-climber-location-information.mountain-hut-name')}${
                  feature?.properties?.koya_name
                }</div>
                    </div>
                  `;

                this.infoWindow = new google.maps.InfoWindow({ content });
                (this.infoWindow as any).source = 'lodgeMarker';
                this.infoWindow.open(this.map, lodgeMarker);
              });

              this.mountainLodges.push(lodgeMarker);
            }
          });
        }
      }

      // Add zoom changed listener for trail weights
      this.map.addListener('zoom_changed', () => {
        const zoom = this.map.getZoom();
        if (!zoom) return;

        // Update trail weights
        this.mountainTrails.forEach(trails => {
          trails.forEach(trail => {
            trail.setOptions({ strokeWeight: zoom >= 15 ? 5 : 3 });
          });
        });

        // Update lodge visibility
        this.mountainLodges.forEach(lodge => {
          lodge.setMap(zoom >= 15 ? this.map : null);
        });

        // Update movement history
        if (this.showMovementHistory) {
          this.updateArrowMarkersVisibility(zoom >= 15);

          if (this.showTimeStamps) {
            if (zoom >= 15) {
              this.showTimeMarkers(this.userLocation?.locationRecords);
            } else {
              this.clearTimeMarkers();
            }
          }
        }
      });
    } catch (error) {
      Helper.handleError(error, this.translateService, this.dialogService);
    }
  }

  /**
   * clearMovementHistory
   */
  clearMovementHistory(): void {
    if (this.movementHistoryPath) {
      this.movementHistoryPath.setMap(null);
      this.movementHistoryPath = null;
    }
    this.clearArrowMarkers();
    this.clearTimeMarkers();
  }

  /**
   * clearArrowMarkers
   */
  clearArrowMarkers(): void {
    this.arrowMarkers.forEach(marker => marker.setMap(null));
    this.arrowMarkers = [];
  }

  clearTimeMarkers(): void {
    this.timeStampMarkers.forEach(marker => {
      marker.setMap(null);
    });
    this.timeStampMarkers = [];
  }

  createArrowMarkers(records: any[]): void {
    this.clearArrowMarkers();

    for (let i = 0; i < records.length; i++) {
      const current = records[i];
      let heading = 0;

      if (i < records.length - 1) {
        const next = records[i + 1];
        heading = google.maps.geometry.spherical.computeHeading(
          new google.maps.LatLng(Number(current.latitude), Number(current.longitude)),
          new google.maps.LatLng(Number(next.latitude), Number(next.longitude))
        );
      } else if (i > 0) {
        const prev = records[i - 1];
        heading = google.maps.geometry.spherical.computeHeading(
          new google.maps.LatLng(Number(prev.latitude), Number(prev.longitude)),
          new google.maps.LatLng(Number(current.latitude), Number(current.longitude))
        );
      }

      // Tạo SVG mới với góc xoay tương ứng
      const rotatedSvgString = this.createRotatedArrowSvg(heading);
      const svgUrl = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(rotatedSvgString);

      const marker = new google.maps.Marker({
        position: {
          lat: Number(current.latitude),
          lng: Number(current.longitude)
        },
        map: this.map,
        icon: {
          url: svgUrl,
          scaledSize: new google.maps.Size(12, 12),
          anchor: new google.maps.Point(6, 6)
        },
        optimized: false,
        zIndex: 3
      });
      marker.addListener('click', () => {
        if (this.infoWindow) {
          this.infoWindow.close();
        }
        const content = `
          <div style="font-family: sans-serif; position: relative; min-width: 230px;">
            <div>${this.translateService.instant('fuji-climber-management.tab-climber-location-information.latitude-label')}${
          current.latitude
        }</div>
            <div>${this.translateService.instant('fuji-climber-management.tab-climber-location-information.longitude-label')}${
          current.longitude
        }</div>
            <div>${this.translateService.instant('fuji-climber-management.tab-climber-location-information.access-date')}${
          current.locationRecordedAt
        }</div>
          </div>
        `;
        this.infoWindow = new google.maps.InfoWindow({ content });
        this.infoWindow.open(this.map, marker);
      });

      this.arrowMarkers.push(marker);
    }
  }

  /**
   * showTimeMarkers
   * @param records
   * @returns
   */
  showTimeMarkers(records: any[]): void {
    this.clearTimeMarkers();

    if (!this.showTimeStamps || this.map.getZoom() < 15 || !records?.length) {
      return;
    }

    records.forEach(record => {
      const time = record.locationRecordedAt.split(' ')[1].substring(0, 5);
      class TimeMarkerOverlay extends google.maps.OverlayView implements ITimeMarkerOverlay {
        private div: HTMLDivElement | null = null;

        constructor(private readonly position: google.maps.LatLng, private readonly time: string) {
          super();
        }

        onAdd(): void {
          this.div = document.createElement('div');
          this.div.className = 'time-marker-label';
          this.div.style.position = 'absolute';
          this.div.style.backgroundColor = '#F8B62D'; // Cùng màu với marker vàng
          this.div.style.opacity = '0.8'; // Độ trong 0.8
          this.div.style.padding = '2px 6px';
          this.div.style.borderRadius = '2px';
          this.div.style.fontSize = '12px';
          this.div.style.whiteSpace = 'nowrap';
          this.div.style.zIndex = '1000';
          this.div.style.fontFamily = 'sans-serif';
          this.div.textContent = this.time;

          const panes = this.getPanes();
          if (panes) {
            panes.overlayLayer.appendChild(this.div);
          }
        }

        draw(): void {
          if (!this.div) return;

          const projection = this.getProjection();
          if (!projection) return;

          const point = projection.fromLatLngToDivPixel(this.position);
          if (!point) return;

          // Điều chỉnh vị trí để nằm sát bên phải của arrow
          this.div.style.left = point.x + 10 + 'px';
          this.div.style.top = point.y - 8 + 'px';
        }

        onRemove(): void {
          if (this.div) {
            this.div.parentNode?.removeChild(this.div);
            this.div = null;
          }
        }
      }

      const position = new google.maps.LatLng(Number(record.latitude), Number(record.longitude));

      const timeMarker = new TimeMarkerOverlay(position, time);
      timeMarker.setMap(this.map);
      this.timeStampMarkers.push(timeMarker);
    });
  }

  private setupDOMObserver(): void {
    this.observer = new MutationObserver(mutations => {
      if (document.querySelector('.gm-style-iw-chr')) {
        this.handleMapInfoWindow();
      }
    });

    // Theo dõi thay đổi trong DOM
    this.observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  }

  private handleMapInfoWindow(): void {
    // Tìm element có class gm-style-iw-chr
    const chrElement = document.querySelector('.gm-style-iw-chr');

    if (chrElement) {
      // Lưu lại button để sử dụng sau
      const button = chrElement.querySelector('button');

      // Tìm vị trí sẽ thêm button vào
      const targetDiv = document.querySelector('.gm-style-iw-d');

      if (button && targetDiv) {
        // Clone button để tránh reference issues
        const clonedButton = button.cloneNode(true) as HTMLElement;
        clonedButton.style.top = '-16px';
        clonedButton.addEventListener('click', () => {
          if (this.infoWindow) {
            this.infoWindow.close();
          }
        });
        // Xóa element gm-style-iw-chr
        chrElement.remove();

        // Thêm button vào vị trí mới
        targetDiv.appendChild(clonedButton);
        targetDiv.setAttribute(
          'style',
          `display: flex;
          margin: 12px 0px;
          position: relative;`
        );
      }
    }
  }

  /**
   * createRotatedArrowSvg
   * @param heading
   * @returns
   */
  createRotatedArrowSvg = (heading: number) => {
    return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="75" viewBox="0, 0, 50, 75">
  <g transform="rotate(${heading} 25 37.5)"> <!-- Xoay quanh điểm giữa SVG -->
    <path d="M24 0 L0 75 L50 75 Z" fill="#f8b62d"/>
  </g>
</svg>`;
  };
}
export interface MapSettings {
  baseMapUrl: string;
  attribution: string;
  centerCoordinates: [number, number];
  zoomLevel: {
    default: number;
    max: number;
    min: number;
  };
}

export interface ITimeMarkerOverlay extends google.maps.OverlayView {
  draw(): void;
  onAdd(): void;
  onRemove(): void;
}
